import { Routes, Route, BrowserRouter } from "react-router-dom"
import Layout from "./components/layout";
import Home from "./pages/home/home"

function App() {
  return (
    <Layout>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Layout>
  )
}

export default App;