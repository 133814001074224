import kabiyesi from './kabiyesi.jpg';
import arrowdown from './arrown-down.png';
import oba from './oba.png';
import radio from './radio.png';
import chief from './Oba-Wearing-Beaded-Crown.png'

const images = {
    kabiyesi,
    arrowdown,
    oba,
    radio,
    chief
};

export default images;