import Splitting from "splitting";
import IO from "./observer";
import gsap from "gsap";
import { useEffect } from "react";

const Index = ({ children }) => {
    const P = document.querySelectorAll("[data-animation='paragraph']");
    const H = document.querySelectorAll("[data-animation='header']");

    useEffect(() => {
        P.forEach((item) => {
            const line = Splitting({
                target: item,
                by: "lines",
            });
            line.forEach((splitResult) => {
                const wrappedLines = splitResult.words
                    .map(
                        (wordsArr) => `
                   <span class="word_wrap">
                         ${wordsArr.outerHTML}
                    </span>`
                    )
                    .join("");
                splitResult.el.innerHTML = wrappedLines;
            });

            gsap.set(item.querySelectorAll(".word"), {
                yPercent: 105,
                opacity: 0,
                rotateX: 50,
                transformStyle: "preserve-3d",
            });
            IO(item, { threshold: 0.5 }).then(() => {
                const elem = item.querySelectorAll(".word");
                gsap.to(elem, {
                    yPercent: 0,
                    opacity: 1,
                    rotateX: 0,
                    stagger: elem.length > 100 ? 0.02 : 0.03,
                    duration: elem.length > 100 ? 0.65 : 0.75,
                    ease: "easeOut",
                });
            });
        });

        H.forEach((item) => {
            Splitting({
                target: item,
                by: "chars",
            });
            gsap.set(item.querySelectorAll(".char"), {
                opacity: 0,
                yPercent: 100,
                transformStyle: "preserve-3d",
            });
            IO(item, {
                threshold: 1,
            }).then(() => {
                const elem = item.querySelectorAll(".char");
                gsap.to(elem, {
                    opacity: 1,
                    yPercent: 0,
                    stagger: elem.length > 100 ? 0.01 : 0.05,
                    duration: elem.length > 100 ? 0.3 : 0.6,
                    ease: "easeOut",
                });
            });
        });

    }, [P, H])

    return (
        <div>
            {children}
        </div>
    )
}

export default Index;