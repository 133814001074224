import React, { useEffect } from "react";
import images from "../../assets/images/images";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import LocomotiveScroll from "locomotive-scroll";
import "./index.scss";

const History = () => {
  useEffect(() => {
    // const scroll = new LocomotiveScroll({
    //   el: document.querySelector("[data-scroll-container]"),
    //   smooth: true,
    //   lerp: 0.09,
    // });
    // setTimeout(() => {
    //   scroll.update();
    // }, 1000);
  });

  return (
    <>
      <section className="coming-soon">
        <h1>Coming Soon...</h1>
      </section>
      <div className="history-body">
        <section>
          <div className="splash-1">
            <h4 className="reveal-type" data-animation="paragraph">
              What it <br /> means?
            </h4>
            <p data-animation="paragraph">
              It translates to “There is no on who shall question you” or “ Who
              dare question you”, It is an honorific attributed to the kings in
              Yoruba Land, meant to describe their power and affluence.
            </p>
            <img src={images.oba} alt="oba o" />
          </div>
          <div className="splash-2">
            <h4 data-animation="paragraph">History</h4>
            <p data-animation="paragraph">
              It translates to “There is no on who shall question you” or “ Who
              dare question you”, It is an honorific attributed to the kings in
              Yoruba Land, meant to describe their power and affluence.It
              translates to “There is no on who shall question you” or “ Who
              dare question you”, It is an honorific attributed to the kings in
              Yoruba Land, meant to describe their power and affluence.It
              translates to “There is no on who shall question you” or “ Who
              dare question you”, It is an honorific attributed to the kings in
              Yoruba Land, meant to describe their power and affluence.
            </p>
            <h5 data-animation="paragraph">Ká bí yo è sí o</h5>
          </div>
        </section>
        <section className="section-2">
          <h1 data-animation="header">
            Eulogy/ spoken <br /> word!
          </h1>
          <div className="card">
            <h5 data-animation="paragraph">
              It translates to “There is no on who shall question you” or “ Who
              dare question you”, It is an honorific attributed to the kings in
              Yoruba Land, meant to describe their power and affluence.It
              translates to “There is no on who shall question you” or “ Who
              dare question you”, It is an honorific attributed to the kings in
              Yoruba Land, meant to describe their power and affluence.It
              translates to “There is no on who shall question you” or “ Who
              dare question you”, It is an honorific attributed to the kings in
              Yoruba Land, meant to describe their power and affluence.
            </h5>
          </div>
        </section>
        <section className="section-3">
          <div className="class-1">
            <img src={images.chief} alt="chief" />
            <h1 data-animation="header">
              IT’S NOT JUST A <br /> WORD, IT’S AN <br /> AUTHORITY
            </h1>
          </div>
          <div className="class-2">
            <div className="images">
              <img src={images.oba} alt="oba" />
              <img src={images.oba} alt="oba" />
              <img src={images.oba} alt="oba" />
              <img src={images.oba} alt="oba" />
            </div>
            <div>
              <p data-animation="header">
                It translates to “There is no on who shall question you” or “
                Who dare question you”, It is an honorific attributed to the
                kings in Yoruba Land, meant to describe their power and
                affluence.It translates to “There is no on who shall question
                you” or “ Who dare question you”, It is an honorific attributed
                to the kings in Yoruba Land, meant to describe their power and
                affluence.It translates to “There is no on who shall question
                you” or “ Who dare question you”, It is an honorific attributed
                to the kings in Yoruba Land, meant to describe their power and
                affluence.
              </p>
            </div>
          </div>
        </section>
        <section className="section-4">
          <h1 data-animation="paragraph">
            KABIYESI - THE <br /> EXPERIENCE
          </h1>
        </section>
      </div>
    </>
  );
};

export default History;
