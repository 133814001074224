import React, { useEffect } from "react";
import "./index.scss";
import { gsap, Power2 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const TopBars = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.fromTo(
      ".topbars-body span",
      { x: "-150px", opacity: 0 },
      {
        x: "0px",
        stagger: 0.08,
        duration: 0.5,
        opacity: 1,
        delay: 1,
      }
    );

    gsap.fromTo(
      ".line-1",
      { height: "1px" },
      {
        scrollTrigger: {
          start: "top center",
          end: "bottom center",
          scrub: true,
        },
        height: "100%",
        ease: Power2.easeIn,
      }
    );
    gsap.fromTo(
      ".line-2",
      { height: "1px" },
      {
        scrollTrigger: {
          start: "top center",
          end: "bottom center",
          scrub: true,
        },
        height: "100%",
        ease: Power2.easeIn,
      }
    );
    gsap.fromTo(
      ".line-3",
      { height: "-70rem" },
      {
        scrollTrigger: {
          start: "top center",
          end: "bottom center",
          scrub: true,
        },
        height: "100%",
        ease: Power2.easeIn,
      }
    );
  });

  return (
    <div className="topbars-body">
      <span className="line-1"></span>
      <span className="line-2"></span>
      <span className="line-3"></span>
    </div>
  );
};

export default TopBars;
