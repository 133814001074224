import React, { useEffect, useRef } from "react";
import "./index.scss";

export default function Audio({ isModalOpen, setIsModalOpen }) {
  const modalRef = useRef(null);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOverlayClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("click", handleOverlayClick);
    } else {
      document.removeEventListener("click", handleOverlayClick);
    }

    return () => {
      document.removeEventListener("click", handleOverlayClick);
    };
  }, []);

  return (
    <>
      {isModalOpen ? (
        <div className="audio-body">
          <section ref={modalRef}>
            <h3>Allow Audio?</h3>
            <p>
              Kabiyesi is an audiovisual experience design and developed to
              provide an immersive feel into the story it’s meant to tell, we
              recommend allowing audio!
            </p>
            <div>
              <span onClick={closeModal}>No</span>
              <span onClick={closeModal}>Yes</span>
            </div>
          </section>
        </div>
      ) : null}
    </>
  );
}
