import React, { useState } from "react"
import TopBars from "./topBars/topbars"
import { KabiyesiHanger } from "./icons/icons"
import Loader from "./loader/loader"


const Layout = ({ children }) => {
    const [loader, setLoader] = useState(true);
    const state = true
    const checker = loader ?? state;
    console.log(checker, "hello")

    return (
        <div >
            {loader ? (
                <Loader setLoader={setLoader} />
            ) : (
                <>
                    <KabiyesiHanger />
                    <TopBars />
                    {children}
                </>
            )
            }
        </div>
    )
}

export default Layout;