import "./index.scss";

const Credit = () => {
  return (
    <div className="credit-body">
      <h1>Credits</h1>
      <section className="design-develop">
        <div>
          <h5>Designed by</h5>
          <h2>LEKANDEV</h2>
        </div>
        <div>
          <h5>Developed by</h5>
          <h2>ARNOLD</h2>
        </div>
      </section>
      <section className="credits">
        <div className="team">
          <ul>
            <li>
              Developer <span>Arnold</span>
            </li>
            <li>
              Designer <span>lekandev</span>
            </li>
            <li>
              Content Writer <span>Inuyasha</span>
            </li>
            <li>
              Spoken Word artiste<span>Timeleyin</span>
            </li>
            <li>
              Audo<span>ffmpeg</span>
            </li>
            <li>
              Image<span>Pinterest</span>
            </li>
          </ul>
        </div>
        <div className="socials">
          <ul>
            <li>Twitter</li>
            <li>Instagram</li>
            <li>Github</li>
            <li>Pinterest</li>
          </ul>
        </div>
        <div className="about">
          <p>
            It translates to “There is no on who shall question you” or “ Who
            dare question you”, It is an honorific attributed to the kings in
            Yoruba Land, meant to describe their power and affluence.
          </p>
        </div>
      </section>
      <section className="done">
        <h2>SCROLL BACK UP</h2>
        <p>
          2023 Audiovisual experience by <span> Arnold </span> et{" "}
          <span> lekandev </span>
        </p>
      </section>
    </div>
  );
};

export default Credit;
