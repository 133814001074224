import { useState, useEffect, useRef } from "react";
import gsap, { Power3, Power4 } from "gsap";
import "./index.scss";

export default function Loader({ setLoader }) {
  let evencard = Array(2).fill("");
  let oddcard = Array(2).fill("");
  const boxRef = useRef(null);
  const box2Ref = useRef(null);
  const barRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [displayBar, setdisplayBar] = useState(false);

  const increaseProgress = () => {
    if (width < 100) {
      setWidth((prevProgress) => prevProgress + 2);
    } 
    else if (width === 100) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (displayBar === true) {
      const interval = setInterval(increaseProgress, 25);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, displayBar]);

  const updateBar = () => {
    setdisplayBar(true);
  };

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to(".box", {
        scaleY: 1,
        height: 0,
        stagger: 0.3,
        ease: Power3.easeOut,
        duration: 3,
      });
      gsap.to(".box2", {
        scaleY: 1,
        clipPath: "inset(100% 0 0 0)",
        stagger: 0.3,
        ease: Power3.easeOut,
        duration: 3,
      });
      gsap.to(boxRef.current, {
        scaleY: 1,
        height: 0,
        stagger: 0.4,
        ease: Power3.easeOut,
        duration: 2,
      });
      gsap.to(box2Ref.current, {
        scaleY: 1,
        clipPath: "inset(100% 0 0 0)",
        stagger: 0.3,
        ease: Power3.easeOut,
        duration: 3,
      });
      gsap.fromTo(
        ".loadingtext",
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 4,
          delay: 1,
        }
      );
      gsap.to(".loadingparagarph", {
        y: 0,
        delay: 1,
        opacity: 1,
        duration: 1.5,
        ease: Power4.easeIn,
        onComplete: updateBar,
      });
    });
    return () => ctx.revert();
  }, []);

  return (
    <div className="loaderbody">
      <section className="slider">
        <div className="container">
          {evencard.map((card, index) => {
            return (
              <div className="box" key={index}>
                {card}
              </div>
            );
          })}
          {oddcard.map((card, index) => {
            return (
              <div className="box" key={index} ref={box2Ref}>
                {card}
              </div>
            );
          })}
        </div>
        <div className="container">
          {evencard.map((card, index) => {
            return (
              <div className="box2" key={index}>
                {card}
              </div>
            );
          })}
          {oddcard.map((card, index) => {
            return (
              <div className="box2" key={index} ref={box2Ref}>
                {card}
              </div>
            );
          })}
        </div>
      </section>
      <section className="content">
        <div className="container">
          <h1 className="loadingtext">Loading</h1>
          <p className="loadingparagarph">
            It translates to “There is no on who shall question you” or “ Who
            dare question you”, It is an honorific attributed to the kings in
            Yoruba Land, meant to describe their power and affluence.
          </p>
        </div>
        <div id="preloader">
          <div id="percent">{displayBar === true ? `${width}%` : null}</div>
          <div id="bar" style={{ width: `${width}%` }} ref={barRef}></div>
        </div>
      </section>
    </div>
  );
}
