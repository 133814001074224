import React, { useEffect, useState } from "react";
import images from "../../assets/images/images";
import History from "../history/history";
import Credit from "../credit/credit";
import { Audio } from "../../components";
import { gsap, Power4 } from "gsap";
import SplitType from "split-type";
import Index from "../../animations";
import "./index.scss";

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    let ctx = gsap.context(() => {
      // eslint-disable-next-line no-unused-vars
      const SplitParagraph = new SplitType("#p-slipt");
      gsap.fromTo(
        ".char",
        { y: "145px", opacity: 0 },
        {
          y: "0px",
          stagger: 0.06,
          duration: 0.5,
          opacity: 1,
          delay: 1,
        }
      );
      gsap.from(".bottom-text", {
        y: "100px",
        opacity: 0,
        duration: 1.5,
      });
      gsap.fromTo(
        ".bottom-text span",
        {
          y: "l40px",
          opacity: 0,
        },
        {
          y: "0px",
          stagger: 0.08,
          opacity: 1,
          duration: 3,
          delay: 1,
        }
      );
      gsap.from(".bottom-last", {
        y: "100px",
        opacity: 0,
        duration: 1.5,
      });
      gsap.to(".bottom-last img", {
        opacity: 1,
        duration: 2,
        y: 100,
        repeat: Infinity,
        ease: Power4.easeInOut,
        delay: 2,
      });
    });
    return () => ctx.revert();
  }, []);

  return (
    <Index>
      <div
        className="home"
        style={{ visibility: loading ? "hidden" : "visible" }}
      >
        <Audio setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
        <div className="contents">
          <h1 id="p-slipt">Kábíyèsí o</h1>
          <div className="bottom">
            <h3 className="bottom-text">
              A WEB <br /> EXPERIENCE by <br /> <span>Arnold</span> &{" "}
              <span>lèkandev </span>
            </h3>
            <div className="bottom-last">
              <div>
                <img src={images.arrowdown} alt="" />
              </div>
              <h4> Scroll down </h4>
            </div>
          </div>
        </div>
        <div className="objects">
          <Credit />
          <History />
        </div>
      </div>
    </Index>
  );
};

export default Home;
